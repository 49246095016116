

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


          }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).on('load', function () {

    // initialization of popups
    $('.flick-slider').flickity({
      // options
      fade: true,
      cellAlign: 'left',
      contain: true
    });

        $('[data-fancybox="gallery"]').fancybox({
          // Options will go here




          // Enable infinite gallery navigation
      loop: true,

      // Horizontal space between slides
      gutter: 50,

      // Enable keyboard navigation
      keyboard: true,

      // Should allow caption to overlap the content
      preventCaptionOverlap: true,

      // Should display navigation arrows at the screen edges
      arrows: true,

      // Should display counter at the top left corner
      infobar: true,

        });
    ;
    ( function ( $ ) {
     "use strict";
    // Define the PHP function to call from here
     var data = {
       'action': 'mode_theme_update_mini_cart'
     };
     $.post(
       woocommerce_params.ajax_url, // The AJAX URL
       data, // Send our PHP function
       function(response){
         $('#cart').html(response); // Repopulate the specific element with the new content
       }
     );
    // Close anon function.
    }( jQuery ) );

  });

  $(document).on('ready', function () {

    // Javascript to enable link to tab
    var url = document.location.toString();
    if (url.match('#')) {
        $('#nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
    }

    // Change hash for page-reload
    $('.nav-tabs a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    })
    

    // initialization of popups
    $('.flick-slider').flickity({
      // options
      fade: true,
      cellAlign: 'left',
      contain: true
    });

        $('[data-fancybox="gallery"]').fancybox({
          // Options will go here




          // Enable infinite gallery navigation
      loop: true,

      // Horizontal space between slides
      gutter: 50,

      // Enable keyboard navigation
      keyboard: true,

      // Should allow caption to overlap the content
      preventCaptionOverlap: true,

      // Should display navigation arrows at the screen edges
      arrows: true,

      // Should display counter at the top left corner
      infobar: true,

        });

   });


  // JavaScript to be fired on all pages, after page specific JS is fired


})(jQuery); // Fully reference jQuery after this point.
